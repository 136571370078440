import * as Msal from 'msal'
import msalInstance from './AzureMsal2'
import LogRocket from 'logrocket'
export default class Authentication {
  app: Msal.UserAgentApplication
  applicationConfig: any
  authority: 'https://login.microsoftonline.com/organizations'
  constructor() {
    // eslint-disable-next-line
    const redirectUri = process.env.REACT_APP_REPLY_URL

    this.applicationConfig = {
      clientID: '62cbe330-ac99-4882-ac82-22c4e3353253',
      graphScopes: ['openid', 'profile', 'User.Read', 'user.readbasic.all'],
    }
    this.app = new Msal.UserAgentApplication({
      auth: {
        authority:
          process.env.REACT_APP_SERVICE === 'abanca'
            ? 'https://login.microsoftonline.com/common/'
            : this.authority,
        clientId: this.applicationConfig.clientID,
        redirectUri: redirectUri,
      },
      cache: {
        cacheLocation: 'localStorage',
      },
    })
  }

  /**
   * Login Para Iniciar Sesion en todas las instancias a exepcion de CaixaBank se usa Msal 1 y
   * loginCaixa es para Iniciar Sesion en CaixaBank se usa Msal 2
   *
   * */
  login = async () => {
    try {
      const idToken = await this.app.loginPopup({
        scopes: ['user.read', 'user.readbasic.all'],
      })
      if (idToken) {
        if (
          process.env.REACT_APP_SERVICE === 'softtek' ||
          process.env.REACT_APP_SERVICE === 'softtekautomation'
        ) {
          const myHeaders = new Headers()
          myHeaders.append('Content-Type', 'application/json')
          const date = new Date()
          const raw = JSON.stringify({
            instance:
              process.env.REACT_APP_SERVICE === 'softtek'
                ? 'Softtek'
                : 'SofttekAutomation',
            username: idToken.account.name,
            id: idToken.account.userName,
            day: date.getDate(),
            month: date.getMonth() + 1,
            year: date.getFullYear(),
          })

          fetch(
            'https://us-central1-innotekheb-c4a92.cloudfunctions.net/innotekAuth-loggedInRegistry',
            {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow',
            }
          )
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.error(error))
        }
        LogRocket.identify(idToken.account.name, {
          name: idToken.account.name,
          email: idToken.account.userName,

          // Add your own custom user variables here, ie:
          subscriptionType: 'pro',
        })
        //await this.setAccessToken()
        localStorage.setItem('idToken', idToken.idToken.rawIdToken)
        return idToken
      } else {
        console.log('something went wrong')
        return null
      }
    } catch (e) {
      console.log('something went wrong')
      return null
    }
  }
  loginCaixa = async () => {
    try {
      var loginRequest = {
        scopes: ['user.read', 'user.readbasic.all'],
      }
      const loginResponse = await msalInstance.loginPopup(loginRequest)
      if (loginResponse) {
        LogRocket.identify(loginResponse.account.name, {
          name: loginResponse.account.name,
          email: loginResponse.account.username,

          // Add your own custom user variables here, ie:
          subscriptionType: 'pro',
        })
        //Se agrega el token que servira para verificar que este iniciada la sesion
        localStorage.setItem('idToken', loginResponse.idToken)
        return loginResponse
      } else {
        console.log('something went wrong')
        return null
      }
    } catch (e) {
      console.log('something went wrong', e)
      return null
    }
  }
  /**
   * Logout funciona para poder serar sesion en todas las instancias
   * que usen MSAL 1
   *
   * La función de logoutCaixa para cerrar sesion en la instancia de CaixaBank
   */
  logout = () => {
    this.app.logout()
  }
  logoutCaixa = () => {
    //ayuda a redirigir cuando se cierra sesión
    const logoutRequest = { mainWindowRedirectUri: '/login' }
    msalInstance.logoutPopup(logoutRequest).then(x => {
      localStorage.clear() //Limpia el localStorage por que logoutPopup no lo hace
    })
  }

  getToken = async () => {
    try {
      const accessToken = await this.app.acquireTokenSilent({
        scopes: ['user.read', 'user.readbasic.all'],
      })

      return accessToken
    } catch (error) {
      try {
        const accessToken_1 = await this.app.acquireTokenPopup({
          scopes: ['user.read', 'user.readbasic.all'],
        })
        return accessToken_1
      } catch (err) {
        // do nothing
      }
    }
  }

  acquireUserTokenSilent = async () => {
    const auth_token_prom = new Promise(acc => {
      this.app
        .acquireTokenSilent({
          scopes: ['user.read', 'user.readbasic.all'],
        })
        .then(token => {
          acc({ newIdToken: token, error: null })
        })
        .catch(error => {
          acc({ newIdToken: null, error: error })
        })
    })
    return auth_token_prom
  }
  /**
   * La funcion de getIdToken funciona para iniciar sesión en las instancias
   * que usen MSAL1,
   * en el caso de la función getIdTokenCaixa se usa para iniciar sesión en
   * CaixaBank que usa MSAL2
   */
  getIdToken() {
    const idToken = localStorage.getItem(Msal.Constants.idTokenKey)
    return idToken
  }

  getIdTokenCaixa() {
    const idToken = localStorage.getItem('idToken')
    console.log('msal2')
    return idToken
  }

  // getAccessToken = () => {
  //   // @ts-ignore
  //   return this.app.user.accessToken
  // }

  setAccessToken = async () => {
    const account = this.app.getAccount()
    if (account) {
      try {
        const tokenObj = await this.app.acquireTokenSilent({
          scopes: ['user.read', 'user.readbasic.all', 'openid', 'profile'],
        })
        console.log(tokenObj)
        localStorage.setItem('token', tokenObj.accessToken)
      } catch (e) {
        console.log(e)
      }
    }
  }

  setAccessTokenCaixa = async () => {
    const account = msalInstance.getAllAccounts()[0]

    if (account) {
      try {
        const tokenObj = await msalInstance.acquireTokenSilent({
          scopes: ['user.read', 'user.readbasic.all', 'openid', 'profile'],
        })
        console.log(tokenObj)
        localStorage.setItem('token', tokenObj.accessToken)
      } catch (e) {
        console.log(e)
      }
    }
  }
}
